import { Typography } from "../../Typography/Typography.jsx";
import { Box } from "../../Box/Box.jsx";
import { Spacer } from "../../Spacer/Spacer.jsx";

import chatmp4 from "../../videos/chat.mp4"
import chatwebm from '../../videos/chat.webm';

import "./Block4.css";

export const Block4 = ({ data }) => {
    const block4 = data;
    return (
        <Box justifyContent="center" className="hu_block4_1">
            <Box flexDirection="column">
                <Typography component="h2" variant="h2_2" color="gray10_1" className="hu_block4_tg1">
                    {block4.title}
                </Typography>
                <Box className="hu_block4_2">
                <Box flexDirection="column" className="hu_block4_3">
                    <Spacer size={40} tabSize={40} mobSize={15} />
                    <Typography component="p" variant="h4" color="gray10_1" className="hu_block4_tg2">
                        {block4.text}
                    </Typography>
                    <Spacer size={20} tabSize={0} mobSize={0} />
                    <a
                        href={block4.button.url}
                        target="_blank"
                        rel="noreferrer"
                        className="hu_block4_button"
                    >
                        <Typography component="span" variant="textXS" color="black-1">
                            {block4.button.label}
                        </Typography>
                    </a>
                </Box>
                <Box className="hu_block4_video">
                    <video
                    autoPlay
                    muted
                    loop
                    style={{ maxWidth: "100%", height: "auto" , borderRadius: "20px"}}
                    >
                        <source src={chatmp4} type="video/mp4" />
                        <source src={chatwebm} type="video/webm" />
                    </video>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};