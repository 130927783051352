import { Typography } from "../../Typography/Typography.jsx";
import { Box } from "../../Box/Box.jsx";
import { Spacer } from "../../Spacer/Spacer.jsx";

import "./Block5.css";

export const Block5 = ({ data }) => {
    const block5 = data;
    return (
        <Box justifyContent="center" className="">
            <Box flexDirection="column" className="hu_block5_2">
                <Typography component="h2" variant="h2_2" color="main_1" className="hu_block5_tg1">
                    {block5.title}
                </Typography>
                <Spacer size={40} tabSize={40} mobSize={20} />
                <Box className="hu_block5_3">
                    {block5.items.map((item, i) => {
                        return (
                            <Box key={i} flexDirection="column" className="hu_block5_4">
                                <img alt={item.title} src={item.icon} className="hu_block5_icon" />
                                <Spacer size={10} tabSize={0} mobSize={0} />
                                <Typography component="h5" variant="h4" color="main_1" className="">
                                    {item.title}
                                </Typography>
                                <Spacer size={5} tabSize={0} mobSize={0} />
                                <Typography component="p" variant="textXS_2" color="black_1" className="">
                                    {item.text}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};