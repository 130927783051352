import { Typography } from "../../Typography/Typography.jsx";
import { Box } from "../../Box/Box.jsx";
import { Spacer } from "../../Spacer/Spacer.jsx";

import "./Block2.css";

export const Block2 = ({ data }) => {
    const block2 = data;
    return (
        <Box justifyContent="center" className="">
            <Box flexDirection="column" className="hu_block2_2">
                <Typography component="h2" variant="h2_2" color="main_1" className="hu_block2_tg1">
                    {block2.title}
                </Typography>
                <Spacer size={40} tabSize={40} mobSize={20} />
                <Typography component="p" variant="h3_2" color="gray10_1" className="hu_block2_tg2">
                    {block2.text}
                </Typography>
            </Box>
        </Box>
    );
};