import { useEffect, useState, useRef, useMemo } from "react";

import { Box } from "../../components/Box/Box";
import { Spacer } from "../../components/Spacer/Spacer";
import { Typography } from "../../components/Typography/Typography.jsx";
import { Block1 } from '../../components/HomeUpdate/Block1/Block1';
import { Block2 } from '../../components/HomeUpdate/Block2/Block2';
import { Block3 } from '../../components/HomeUpdate/Block3/Block3';
import { Block4 } from '../../components/HomeUpdate/Block4/Block4';
import { Block5 } from '../../components/HomeUpdate/Block5/Block5';
import { Block6 } from '../../components/HomeUpdate/Block6/Block6';
import { Block9 } from '../../components/HomeUpdate/Block9/Block9.jsx';

import logo from "../../images/logo.svg";
import { dataUpdate } from "../../data.js";
import "./HomeUpdate.css";

const WIDTH_WRAPPER = 1166;

const HomeUpdate = () => {
    // eslint-disable-next-line
    const [isScrolled, setIsScrolled] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const myRef = useRef(null);

    const handleResize = () => {
        // Update state when window width changes
        setWindowWidth(window.innerWidth);
      };
    
      useEffect(() => {
        // Subscribe to the resize event
        window.addEventListener("resize", handleResize);
    
        // This is a cleanup function, it will be called before the component is removed.
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    
      useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 2000) {
            setIsScrolled(true);
          } else {
            setIsScrolled(false);
          }
        };
    
        window.addEventListener("scroll", handleScroll);
    
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);
    
      useEffect(() => {
        let lastScrollY = window.scrollY;
    
        const handleScroll = () => {
          const currentPosition = window.scrollY;
          const isScrollingDown = currentPosition > lastScrollY;
          const scrollDifference = Math.abs(currentPosition - lastScrollY);
    
          if (currentPosition >= 3100 && currentPosition <= 4500) {
            const scrollAmount = isScrollingDown
              ? scrollDifference
              : -scrollDifference;
            myRef.current.scrollLeft += scrollAmount * 4;
          }
    
          lastScrollY = currentPosition; // Update lastScrollY for the next call
        };
    
        if (windowWidth > 1024) {
          window.addEventListener("scroll", handleScroll);
        }
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, [windowWidth]);
    
      const offset = useMemo(() => {
        return (windowWidth - WIDTH_WRAPPER) / 2;
      }, [windowWidth]);

    const { block1, block2, block3, block4, block5, block6, block7, block9, footer } = dataUpdate;
    return (
        <div className="first_bg">
            <Box flexDirection="column" theme="withBackgroundColor">
                {/* HEADER */}
                <div className="header">
                    <a href="/" rel="noreferrer">
                        <img alt="Maracas AI" src={logo} />
                    </a>
                </div>

                {/* BLOCK1 */}
                <Spacer size={110} tabSize={0} mobSize={0} />
                <Block1 data={block1} />
                <Spacer size={75} tabSize={75} mobSize={70} />

                {/* BLOCK2 */}
                <Block2 data={block2} />
                <Spacer size={75} tabSize={75} mobSize={70} />

                {/* BLOCK3 */}
                <Block3 data={block3} />
                <Spacer size={75} tabSize={75} mobSize={40} />

                {/* BLOCK4 */}
                <Block4 data={block4} />
                <Spacer size={75} tabSize={75} mobSize={40} />

                {/* BLOCK5 */}
                <Block5 data={block5} />
                <Spacer size={75} tabSize={75} mobSize={40} />

                {/* BLOCK6 */}
                <Block6 data={block6} />
                <Spacer size={75} tabSize={75} mobSize={40} />

                {/* BLOCK7 */}
                <Box justifyContent="center">
                    <Box flexDirection="column" className="hu_block7_2">
                        <Typography component="h2" variant="h2_2" color="main_1" className="hu_block7_tg1">
                            {block7.title}
                        </Typography>
                        <Spacer size={40} tabSize={40} mobSize={20} />
                    </Box>
                </Box>
                {offset && (
                    <div
                        ref={myRef}
                        className="scroll"
                        style={{ paddingLeft: windowWidth > 1024 ? `${offset}px` : '20px'}}
                    >
                        <Box theme="block2" className="block2_4">
                        {block7.items.map((item, i) => {
                            return (
                            <Box key={i} flexDirection="column" theme="child_block2">
                                <Typography component="h4" variant="h4" color="gray10_1" className="block2_title">
                                {item.title}
                                </Typography>
                                <Spacer size={5} />
                                <Typography component="p" variant="textXS" color="gray20_1" className="block2_subtitle">
                                {item.text}
                                </Typography>
                            </Box>
                            );
                        })}
                        </Box>
                        <div className="add-child" style={{ width: windowWidth > 1024 ? `${offset}px` : '20px' }} />
                    </div>
                )}
                <Spacer size={75} tabSize={75} mobSize={40} />

                {/* BLOCK8 */}
                <Block9 data={block9} footer={footer} />
            </Box>
        </div>
    );
};

export default HomeUpdate;