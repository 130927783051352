import { Typography } from "../Typography/Typography.jsx";
import { Box } from "../Box/Box.jsx";

import "./Footer.css";

export const Footer = ({ data }) => {
  const footer = data;
  return (
    <Box className="footer">
        <Typography component="span" variant="h5" color="gray10-1" className="footer_tg1">
            {footer.title}
        </Typography>
        <Box className="footer_links">
            {footer.links.map((link, i) => {
                return (
                    <a
                        key={i}
                        href={link.url}
                        rel="noreferrer"
                        className="footer_link"
                    >
                        <Typography component="span" variant="h5" color="gray10-1">
                            {link.label}
                        </Typography>
                    </a>
                )
            })}
        </Box>
    </Box>
  )
};