import { Typography } from "../../Typography/Typography.jsx";
import { Box } from "../../Box/Box.jsx";
import { Spacer } from "../../Spacer/Spacer.jsx";

import chatmp4 from "../../videos/chat.mp4"
import chatwebm from '../../videos/chat.webm';

import "./Block1E.css";

export const Block1E = ({ data }) => {
  const block1E = data;
  return (
    <Box justifyContent="center" className="block1E_1">
      <Box className="block1E_1_wrapper">
      <Box flexDirection="column" className="block1E_2">
        <Typography component="h1" variant="h1_2" color="gray10_1" className="block1E_2_tg_1">
            {block1E.title}
        </Typography>
        <Spacer size={15} tabSize={0} mobSize={0} />
        <Typography component="p" variant="h3" color="gray10_1" className="block1E_2_tg_2">
            {block1E.subtitle}
        </Typography>
        <Spacer size={20} tabSize={0} mobSize={0} />
            <a
              href={block1E.button.url}
              target="_blank"
              className="header_button block1E_header_button"
              rel="noreferrer"
            >
              <Typography component="span" variant="textXS" color="black-1">
                {block1E.button.title}
              </Typography>
            </a>
            <Spacer size={51} tabSize={92} mobSize={0} />
      </Box>
        <Box className="block1E_video">
        <video
          autoPlay
          muted
          loop
          style={{ maxWidth: "100%", height: "auto" }}
        >
            <source src={chatmp4} type="video/mp4" />
            <source src={chatwebm} type="video/webm" />
        </video>
        </Box>
      </Box>
    </Box>
  );
};
