// Images for Block1 (Customized\nGenerative AI)
import photo_Ivan from "./images/Ivan.jpg";

// Icons for Block3 (Core Services)
import block3_icon1 from "./icons/block3_icon1.svg";
import block3_icon2 from "./icons/block3_icon2.svg";
import block3_icon3 from "./icons/block3_icon3.svg";
import block3_icon4 from "./icons/block3_icon4.svg";
import block3_icon5 from "./icons/block3_icon5.svg";
import block3_icon6 from "./icons/block3_icon6.svg";

// Images for Block4 (Industry-Specific AI Solutions)
import block4_image1 from "./images/block4_image1.webp";
import block4_image2 from "./images/block4_image2.webp";
import block4_image3 from "./images/block4_image3.webp";
import block4_image4 from "./images/block4_image4.webp";
import block4_image5 from "./images/block4_image5.webp";
import block4_image6 from "./images/block4_image6.webp";

// Images for Block5 (Digital Retail Transformation)
import block5_image1 from "./images/block5_image1.webp";
import block5_image2 from "./images/block5_image2.webp";
import block5_image3 from "./images/block5_image3.webp";

export const dataHome = {
  block1: {
    title: "Customized\nGenerative AI",
    subtitle: "For the Modern Enterprise",
    text: "Ivan brings a storied 12-year history in AI,\nunderscored by his role in a global top-10\nlanguage model initiative. He’s the brains\nbehind Maracas.ai, leveraging a robust\nnetwork of skilled developers to deliver\nnuanced AI solutions.",
    signature: {
      image: photo_Ivan,
      name: "Ivan",
      post: "Your AI Strategist",
    },
  },
  block2: {
    title: "Our Methodology",
    items: [
      {
        title: "Comprehensive Evaluation",
        text: "We dive deep into your company’s\necosystem to discover AI opportunities.",
      },
      {
        title: "Bespoke Strategy Formation",
        text: "Crafting a unique AI plan that resonates\nwith your business trajectory.",
      },
      {
        title: "Seamless AI Integration",
        text: "Fitting new AI gears into the machinery\nof your existing systems.",
      },
      {
        title: "Seamless AI Integration",
        text: "Fitting new AI gears into the machinery\nof your existing systems.",
      },
    ],
  },
  block3: {
    title: "Core Services",
    items: [
      {
        icon: block3_icon1,
        title: "Strategic AI Roadmapping",
        text: "We chart a future-forward AI course that aligns\nwith your business ambitions, ensuring\nsustainable growth and technological relevance.",
      },
      {
        icon: block3_icon2,
        title: "Custom AI Solutions Development",
        text: "Our AI solutions aren’t just cutting-edge; they’re cut-to-fit,\ntailor-made to address your specific business challenges.",
      },
      {
        icon: block3_icon3,
        title: "AI Integration Services",
        text: "We weave AI into the fabric of your existing\ninfrastructure, enhancing synergy across your IT landscape.",
      },
      {
        icon: block3_icon4,
        title: "AI Optimization",
        text: "Tuning the engines of your AI apparatus, we amplify their\nperformance and maximize operational efficiencies.",
      },
      {
        icon: block3_icon5,
        title: "Data Science and Analytics",
        text: "We translate data into narratives, uncovering\npatterns and predictions through advanced\nanalytics and insightful visualizations.",
      },
      {
        icon: block3_icon6,
        title: "AI Ethics and Compliance Consulting",
        text: "Our ethical compass and legal acumen ensure your AI\ndeployment isn’t just effective—it’s also responsible and\ncompliant.",
      },
    ],
  },
  block4: {
    title: "Industry-Specific AI Solutions",
    items: [
      {
        title: "Enhanced Customer Service",
        text: "AI-driven systems that provide\npersonalized customer interactions\nand support.",
        block: {
          title: "Maracas AI support",
          image: block4_image1,
        },
      },
      {
        title: "Advanced Market Analytics",
        text: "Harnessing AI to gain a deeper\nunderstanding of market trends\nand customer behavior.",
        block: {
          title: "Maracas Market Trends Dashboard",
          image: block4_image2,
        },
      },
      {
        title: "Content Generation and SEO",
        text: "Utilizing AI to create compelling\ncontent and improve search engine\nvisibility.",
        block: {
          title:
            "Content for “best maracas for samba” “eco-friendly percussion instruments”",
          image: block4_image3,
        },
      },
      {
        title: "Precision Inventory Management",
        text: "AI solutions for optimizing stock\nlevels and predicting demand more\naccurately.",
        block: {
          title: "Inventory",
          image: block4_image4,
        },
      },
      {
        title: "Smart Recruitment Tools",
        text: "AI-powered assistance to\nstreamline the talent acquisition\nprocess.",
        block: {
          title: "Recruitment",
          image: block4_image5,
        },
      },
      {
        title: "Proactive Sales Enablement",
        text: "Leveraging AI to empower your\nsales team with insights and\nautomated assistance.",
        block: {
          title: "Maracas Sales Insight App",
          image: block4_image6,
        },
      },
    ],
  },
  block5: {
    title: "Digital Retail Transformation",
    text: "A trailblazing e-retailer faced the challenge\nof scaling customer service and supply chain\noperations efficiently.",
    items: [
      {
        image: block5_image1,
        title: "Challenge",
        text: "They required a nuanced approach to enhance\ncustomer engagement and streamline logistical\noperations without escalating costs.",
      },
      {
        image: block5_image2,
        title: "Solution",
        text: "Maracas.ai implemented a trio of AI-powered\nsolutions: an intuitive support chatbot, a smart\ninventory forecasting system, and a dynamic\ncontent generation tool for SEO optimization.",
      },
      {
        image: block5_image3,
        title: "Outcome",
        text: "The retailer saw a 40% cut in operational expenses\nand a 30% uplift in supply chain efficacy, along with\na measurable jump in online customer interaction.",
      },
    ],
    quote1: `As we navigated the complexities of scaling our e-commerce platform,\nMaracas.ai emerged as a beacon of innovation, guiding us through\na transformative journey. The AI solutions tailored by Ivan and his team\ndidn't just resolve our challenges — they redefined our operations.`,
    quote2: `We've seen a remarkable optimization in customer service efficiency\nand a new level of precision in our supply chain management. Thanks\nto Maracas.ai, we are not just thriving; we are setting new benchmarks\nin the digital retail space.`,
  },
  block6: {
    title: "Empower Your Business with AI",
    text: "Chart your path to AI excellence\nwith Ivan’s expertise",
    button: {
      title1: "Book Consultation",
      title2: "Book a Strategic Consultation with Ivan",
      url: "https://calendly.com/maracas-ai/30-minute-consultation",
    },
  },
};

export const dataUpdate = {
  block1: {
    title: "Unleashing the Power of Generative AI for Your Enterprise",
    subtitle:
      "Custom AI solutions crafted to transform your business operations.",
    button: {
      url: "https://calendly.com/maracas-ai/maracas-ai-introduction-call",
      label: "Let's Innovate Together",
    },
  },
  block2: {
    title: "About Maracas.ai",
    text: `At Maracas.ai, we're not just another tech company — we're a passionate collective of AI visionaries dedicated to reshaping the future of enterprise operations.\n\nWe believe in the limitless potential of generative AI and its ability to unlock unprecedented efficiencies and opportunities for businesses like yours`,
  },
  block3: {
    title: "Why Choose Maracas.ai?",
    list: [
      {
        title: "Cutting-Edge Expertise",
        text: "Our team thrives at the forefront of generative AI innovation, ensuring you receive solutions that are both state-of-the-art and future-proof.",
      },
      {
        title: "Collaborative Partnership",
        text: "Your success is our mission. We work closely with you at every step to ensure our solutions not only meet but exceed your expectations.",
      },
      {
        title: "Bespoke Solutions",
        text: `We understand that every enterprise is unique. That's why we invest dozens of hours customizing and training our AI to align perfectly with your brand's voice, values, and systems.`,
      },
    ],
  },
  block4: {
    title: "Introducing Echo by Maracas.ai",
    text: `Meet Echo, the first CX chatbot that truly understands your customers. Echo doesn't just respond — it resonates.\n\nBy exhibiting human-like empathy and understanding, Echo transforms customer interactions into meaningful engagements, enhancing satisfaction and loyalty.`,
    button: {
      url: "https://calendly.com/maracas-ai/maracas-ai-introduction-call",
      label: `Discover Echo's Impact`,
    },
  },
  block5: {
    title: "Our Services",
    items: [
      {
        icon: block3_icon4,
        title: "Custom AI Development",
        text: "Tailored AI solutions designed to address your specific operational challenges.",
      },
      {
        icon: block3_icon6,
        title: "AI Strategy Consulting",
        text: "Navigate the AI landscape with confidence, guided by our expert insights.",
      },
      {
        icon: block3_icon5,
        title: "Enterprise Integration",
        text: "Seamlessly blend AI technologies with your existing systems for optimal performance.",
      },
    ],
  },
  block6: {
    title: 'Success Stories',
    quote: `Maracas.ai didn't just provide us with an AI solution — they delivered a transformation. Our operations are more efficient, and our customer satisfaction has never been higher.`,
    label: 'Alexandra Reynolds, CEO of InnovateTech',
  },
  block7: {
    title: 'Our Process',
    items: [
      {
        title: "Discovery",
        text: "We delve deep into understanding your needs and goals.",
      },
      {
        title: "Design",
        text: "Crafting a bespoke AI strategy tailored to your enterprise.",
      },
      {
        title: "Development",
        text: "Bringing the strategy to life with cutting-edge technology.",
      },
      {
        title: "Design",
        text: "Crafting a bespoke AI strategy tailored to your enterprise.",
      },
      {
        title: "Development",
        text: "Bringing the strategy to life with cutting-edge technology.",
      },
    ],
  },
  block9: {
    title: "Ready to Transform Your Enterprise?",
    text: "The future waits for no one.\nEmbrace the power of AI today and propel your business into tomorrow.",
    button: {
      title1: "Get in Touch with Our Team",
      title2: "Get in Touch with Our Team",
      url: "https://calendly.com/maracas-ai/maracas-ai-introduction-call",
    },
  },
  footer: {
    title: "© 2024 Maracas.ai. All rights reserved.",
    links: [
      {
        url: "",
        label: "Terms of Service",
      },
      {
        url: "",
        label: "Privacy",
      },
    ],
  },
};

export const dataEcho = {
  block1: {
    title: "Echo: The CX Chatbot\nwith a Human Touch",
    subtitle:
      "Delivering empathy and understanding\nin every customer interaction.",
    button: {
      title: "Request a Demo",
      url: "https://calendly.com/maracas-ai/maracas-ai-introduction-call",
    },
  },
  block2: {
    title: "Experience Customer Service Like Never Before",
    subtitle:
      `In a world inundated with impersonal chatbots, Echo stands apart.\n\nDeveloped by the AI innovators at Maracas.ai, Echo is the first conversational AI platform designed to genuinely understand and empathize with your customers.`,
  },
  block3: {
    title: "Why Echo?",
    items: [
      {
        icon: block3_icon1,
        title: "Human-Like Empathy",
        text: "Echo doesn't just process queries; it senses emotions, adapts its tone, and responds with genuine understanding.",
      },
      {
        icon: block3_icon3,
        title: "Superhuman Efficiency",
        text: "Resolve over 50% of customer inquiries almost instantly, boosting satisfaction while reducing workload.",
      },
      {
        icon: block3_icon2,
        title: "Global Reach",
        text: "With support for hundreds of languages, Echo bridges gaps and connects you with customers worldwide.",
      },
      {
        icon: block3_icon4,
        title: "Seamless Integration",
        text: "Currently compatible with Zendesk, Echo fits effortlessly into your existing support infrastructure.",
      },
    ],
  },
  block4: {
    title: "The Echo Difference",
    subtitle:
      "What sets Echo apart is our unwavering commitment to personalization. Our experts dedicate extensive hours to:",
    items: [
      {
        icon: block3_icon4,
        title: "Customize Tone of Voice",
        text: "Echo mirrors your brand's unique personality, ensuring consistency across all customer touchpoints.",
      },
      {
        icon: block3_icon5,
        title: "Integrate Corporate Knowledge",
        text: "By tapping into your existing knowledge bases, Echo provides accurate and context-rich responses.",
      },
      {
        icon: block3_icon6,
        title: "Align with Systems",
        text: "Echo works in harmony with your current systems, ensuring a smooth and uninterrupted customer experience.",
      },
    ],
  },
  block5: {
    title: "Hear from Our Clients",
    quote:
      "Implementing Echo was like adding a compassionate team member who works 24/7 without fatigue. Our customer feedback has been overwhelmingly positive.",
    label: "Michael Thompson, Head of Customer Experience at GlobalReach",
  },
  block6: {
    title: "How Echo Works",
    items: [
      {
        title: "Deep Customization",
        text: "We tailor Echo to reflect your brand's ethos",
      },
      {
        title: "Expert Training",
        text: "Our team trains Echo using your corporate data for unparalleled accuracy",
      },
      {
        title: "Seamless Deployment",
        text: "Echo integrates with your systems, ready to engage with customers instantly",
      },
      {
        title: "Continuous Improvement",
        text: "With ongoing support and updates, Echo evolves alongside your business",
      },
      {
        title: "Support",
        text: "Ongoing partnership to ensure sustained success.",
      },
    ],
  },
  block7: {
    title: "FAQs",
    items: [
      {
        title: "How is Echo different from other chatbots?",
        text: "Echo combines advanced AI with dedicated human expertise to deliver interactions that feel genuinely human. It's not just about answering questions — it's about building connections.",
      },
      {
        title: "Can Echo handle multiple languages?",
        text: "Echo supports hundreds of languages, making it perfect for global enterprises.",
      },
      {
        title: "What platforms does Echo integrate with?",
        text: "Currently, Echo integrates seamlessly with Zendesk, with more integrations on the horizon.",
      },
    ],
  },
  block8: {
    title: "Elevate Your Customer Experience Today",
    text: "Your customers deserve more than scripted responses.\nThey deserve to be heard, understood, and valued.\nWith Echo, that level of service is not just possible — it's effortless.",
    button: {
      title1: "Schedule Your Personalized Demo",
      title2: "Schedule Your Personalized Demo",
      url: "https://calendly.com/maracas-ai/maracas-ai-introduction-call",
    },
  },
  footer: {
    title: "© 2024 Maracas.ai. All rights reserved.",
    links: [
      {
        url: "",
        label: "Terms of Service",
      },
      {
        url: "",
        label: "Privacy",
      },
    ],
  },
};
