import { Typography } from "../../Typography/Typography.jsx";
import { Box } from "../../Box/Box.jsx";
import { Spacer } from "../../Spacer/Spacer.jsx";

import "./Block5E.css";

export const Block5E = ({ data }) => {
  const block5E = data;
  return (
    <Box justifyContent="center" className="block5E_1">
      <Box flexDirection="column" className="block5E_2">
        <Typography component="h2" variant="h2_2" color="accent_2" className="block5E_2_tg1">
            {block5E.title}
        </Typography>
        <Spacer size={55} mobSize={37} />
        <Typography component="p" variant="textM" color="black_1" className="block5E_2_tg2">
          {block5E.quote}
        </Typography>
        <Spacer size={37} mobSize={29} />
        <Typography component="p" variant="textS" color="gray30_1" className="block5E_2_tg3">
          {block5E.label}
        </Typography>
      </Box>
    </Box>
  );
};
