import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
// eslint-disable-next-line
import Home from './pages/Home/Home';
import HomeUpdate from './pages/HomeUpdate/HomeUpdate';
import Echo from './pages/Echo/Echo';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<HomeUpdate />} />
        <Route path="echo" element={<Echo />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
