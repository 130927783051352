import { Typography } from "../../Typography/Typography.jsx";
import { Box } from "../../Box/Box.jsx";
import { Spacer } from "../../Spacer/Spacer.jsx";

import "./Block3.css";

export const Block3 = ({ data, theme }) => {
  const block3 = data;
  return (
    <Box justifyContent="center" className="block3_1">
      <Box flexDirection="column" className={theme === 'echo' ? 'block3_2 block3E_2' : "block3_2"}>
        <Typography
          component="h2"
          variant={theme === 'echo' ? 'h2_2' : 'h2'}
          color={theme === 'echo' ? 'accent_2' : 'main_05'}
          className="block3_tg1"
        >
          {block3.title}
        </Typography>
        <Spacer size={40} tabSize={30} mobSize={20} />
        <Box flexWrap="wrap" className="block3_3">
          {block3.items.map((item, i) => {
            return (
              <Box key={i} flexDirection="column" className={theme === 'echo' ? 'block3_4 block3E_4' : 'block3_4'}>
                <img
                  aria-hidden
                  alt={item.title}
                  src={item.icon}
                  className="block3_icon"
                />
                <Spacer size={10} />
                <Typography component="h4" variant="h4" color={theme === 'echo' ? 'gray10_1' : "main_1"}>
                  {item.title}
                </Typography>
                <Spacer size={5} />
                <Typography component="p" variant="textXS" color="gray20_1">
                  {item.text}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
