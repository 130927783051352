import { useEffect, useState, useRef, useMemo } from "react";

import { Typography } from "../../components/Typography/Typography.jsx";
import { Box } from "../../components/Box/Box.jsx";
import { Spacer } from "../../components/Spacer/Spacer.jsx";
import { Block3 } from "../../components/Home/Block3/Block3.jsx";
import { Block1E } from "../../components/Echo/Block1E/Block1E.jsx";
import { Block2E } from "../../components/Echo/Block2E/Block2E.jsx";
import { Block4E } from "../../components/Echo/Block4E/Block4E.jsx";
import { Block5E } from "../../components/Echo/Block5E/Block5E.jsx";
import { Block7E } from "../../components/Echo/Block7E/Block7E.jsx";
import { Block8E } from "../../components/Echo/Block8E/Block8E.jsx";

import "./Echo.css";
import logo from "../../images/logo_white.svg";
import { dataEcho } from "../../data.js";

const WIDTH_WRAPPER = 1166;

const Echo = () => {
    // eslint-disable-next-line
    const [isScrolled, setIsScrolled] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const myRef = useRef(null);

    const handleResize = () => {
        // Update state when window width changes
        setWindowWidth(window.innerWidth);
      };
    
      useEffect(() => {
        // Subscribe to the resize event
        window.addEventListener("resize", handleResize);
    
        // This is a cleanup function, it will be called before the component is removed.
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    
      useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 2000) {
            setIsScrolled(true);
          } else {
            setIsScrolled(false);
          }
        };
    
        window.addEventListener("scroll", handleScroll);
    
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);
    
      useEffect(() => {
        let lastScrollY = window.scrollY;
    
        const handleScroll = () => {
          const currentPosition = window.scrollY;
          const isScrollingDown = currentPosition > lastScrollY;
          const scrollDifference = Math.abs(currentPosition - lastScrollY);
    
          if (currentPosition >= 3100 && currentPosition <= 4500) {
            const scrollAmount = isScrollingDown
              ? scrollDifference
              : -scrollDifference;
            myRef.current.scrollLeft += scrollAmount * 4;
          }
    
          lastScrollY = currentPosition; // Update lastScrollY for the next call
        };
    
        if (windowWidth > 1024) {
          window.addEventListener("scroll", handleScroll);
        }
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, [windowWidth]);
    
      const offset = useMemo(() => {
        return (windowWidth - WIDTH_WRAPPER) / 2;
      }, [windowWidth]);

    const { block1, block2, block3, block4, block5, block6, block7, block8, footer } = dataEcho;

    return (
        <div>
            <Box flexDirection="column" theme="withBackgroundEchoColor">
                {/* HEADER */}
                <div className="header header_echo">
                    <a href="/" rel="noreferrer">
                        <img alt="Maracas AI" src={logo} />
                    </a>
                </div>

                {/* BLOCK1 */}
                <Block1E data={block1} />

                {/* BLOCK2 */}
                <Spacer size={100} mobSize={40} />
                <Block2E data={block2} />

                {/* BLOCK3 */}
                <Spacer size={75} mobSize={40} />
                <Block3 theme='echo' data={block3} />

                {/* BLOCK4 */}
                <Spacer size={90} mobSize={40} />
                <Block4E data={block4} />

                {/* BLOCK5 */}
                <Spacer size={75} mobSize={40} />
                <Block5E data={block5} />

                {/* BLOCK6 */}
                <Spacer size={75} mobSize={40} />
                <Box justifyContent="center">
                    <Box flexDirection="column" className="hu_block7_2">
                      <Typography component="h2" variant="h2_2" color="accent_2" className="block6E_tg1">
                      {block6.title}
                  </Typography>
                    </Box>
                </Box>
                <Spacer size={40} mobSize={20} />
                {offset && (
                <div
                    ref={myRef}
                    className="scroll"
                    style={{ paddingLeft: windowWidth > 1024 ? `${offset}px` : '20px'}}
                >
                    <Box theme="block2_echo" className="block2_4">
                    {block6.items.map((item, i) => {
                        return (
                        <Box key={i} flexDirection="column" theme="child_block2_echo">
                            <Typography component="h4" variant="h4" color="gray10_1" className="block2_title">
                            {item.title}
                            </Typography>
                            <Spacer size={5} />
                            <Typography component="p" variant="textXS" color="gray20_1" className="block2_subtitle">
                            {item.text}
                            </Typography>
                        </Box>
                        );
                    })}
                    </Box>
                    <div className="add-child" style={{ width: windowWidth > 1024 ? `${offset}px` : '20px' }} />
                </div>
                )}

                {/* BLOCK7 */}
                <Spacer size={75} mobSize={20} />
                <Block7E data={block7} />

                {/* BLOCK 8 */}
                <Block8E data={block8} footer={footer} />
            </Box>
        </div>
    )
}

export default Echo;