import { Typography } from "../../Typography/Typography.jsx";
import { Box } from "../../Box/Box.jsx";
import { Spacer } from "../../Spacer/Spacer.jsx";

import "./Block7E.css";

export const Block7E = ({ data }) => {
  const block7E = data;
  return (
    <Box justifyContent="center" className="block7E_1">
      <Box flexDirection="column" className="block7E_2">
        <Typography component="h2" variant="h2_2" color="accent_2" className="block7E_tg1">
            {block7E.title}
        </Typography>
        <Spacer size={40} mobSize={20} />
        <div className="block7E_3_wrap">
          {block7E.items.map((item, i) => {
            return (
              <Box key={i} flexDirection="column">
                <Typography component="h3" variant="h3_3" color="gray10_1" className="block7E_tg2">
                    {item.title}
                </Typography>
                <Spacer size={16} tabSize={0} mobSize={0} />
                <Typography component="p" variant="textXS" color="gray10_1">
                    {item.text}
                </Typography>
                <Spacer size={30} mobSize={20} />
              </Box>
            )
          })}
        </div>
        <Spacer size={50} mobSize={30} />
      </Box>
    </Box>
  );
};
