import { Typography } from "../../Typography/Typography.jsx";
import { Box } from "../../Box/Box.jsx";
import { Spacer } from "../../Spacer/Spacer.jsx";

import "./Block2E.css";

export const Block2E = ({ data }) => {
  const block2E = data;
  return (
    <Box justifyContent="center" className="block2E_1">
      <Box flexDirection="column" className="block2E_2">
        <Typography component="h2" variant="h2_2" color="accent_2" className="block2E_2_tg1">
            {block2E.title}
        </Typography>
        <Spacer size={40} tabSize={40} mobSize={20} />
        <Typography component="p" variant="h3_2" color="gray10_1" className="block2E_2_tg2">
            {block2E.subtitle}
        </Typography>
      </Box>
    </Box>
  );
};
