import { Typography } from "../../Typography/Typography.jsx";
import { Box } from "../../Box/Box.jsx";
import { Spacer } from "../../Spacer/Spacer.jsx";
import { Footer } from "../../Footer/Footer.jsx";

import "./Block9.css";

export const Block9 = ({ data, footer }) => {
  const block9 = data;
  return (
    <Box alignItems="center" flexDirection="column" theme="block6" className="block6_1">
      <Spacer size={75} mobSize={40} />
      <Box
        alignItems="center"
        flexDirection="column"
        theme="child_block6"
        className="hu_block9_2"
      >
        <Typography component="h4" variant="h3_3" color="main_1" className="hu_block9_tg1">
          {block9.title}
        </Typography>
        <Spacer size={8} mobSize={8} />
        <Typography component="p" variant="textXS" color="black_1">
          {block9.text}
        </Typography>
        <Spacer size={20} mobSize={20} />
        <a
          href={block9.button.url}
          target="_blank"
          className="block6_button"
          rel="noreferrer"
        >
          <Typography component="span" variant="textM" color="gray10_1" className="block6_2_button_label">
            {block9.button.title1}
          </Typography>
        </a>
      </Box>
      <Spacer size={49} mobSize={20} />
      <Footer data={footer} />
      <Spacer size={15} mobSize={20} />
    </Box>
  );
};
