import { Typography } from "../../Typography/Typography.jsx";
import { Box } from "../../Box/Box.jsx";
import { Spacer } from "../../Spacer/Spacer.jsx";

import "./Block3.css";

export const Block3 = ({ data }) => {
    const block3 = data;
    return (
        <Box justifyContent="center" className="">
            <Box flexDirection="column" className="hu_block3_2">
                <Typography component="h2" variant="h2_2" color="main_1" className="hu_block3_tg1">
                    {block3.title}
                </Typography>
                <Spacer size={40} tabSize={40} mobSize={20} />
                <Box flexDirection="column" className="hu_block3_3">
                    {block3.list.map((item, i) => {
                        return (
                            <Box key={i} className="hu_block3_4">
                                <Typography component="h3" variant="h4" color="main_1" className="hu_block3_tg2">
                                    {item.title}
                                </Typography>
                                <Spacer size={1} tabSize={1} mobSize={6} />
                                <Typography component="p" variant="textXS" color="gray10_1" className="hu_block3_tg3">
                                    {item.text}
                                </Typography>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        </Box>
    );
};