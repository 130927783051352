import { Typography } from "../../Typography/Typography.jsx";
import { Box } from "../../Box/Box.jsx";
import { Spacer } from "../../Spacer/Spacer.jsx";

import "./Block4E.css";

export const Block4E = ({ data }) => {
  const block4E = data;
  return (
    <Box justifyContent="center" className="block4E_1">
      <Box flexDirection="column" className="block4E_2">
        <Typography component="h2" variant="h2_2" color="accent_2" className="block4E_2_tg1">
            {block4E.title}
        </Typography>
        <Spacer size={40} mobSize={20} />
        <Typography component="p" variant="textS_600" color="gray10_1" className="block4E_2_tg2">
            {block4E.subtitle}
        </Typography>
        <Spacer size={30} tabSize={0} mobSize={0} />
        <Box flexWrap="wrap" className="block4E_3">
          {block4E.items.map((item, i) => {
            return (
              <Box key={i} flexDirection="column" className='block4E_4'>
                  <img
                    aria-hidden
                    alt={item.title}
                    src={item.icon}
                    className="block3_icon"
                  />
                  <Spacer size={10} />
                  <Typography component="h4" variant="h4" color='gray10_1'>
                    {item.title}
                  </Typography>
                  <Spacer size={5} />
                  <Typography component="p" variant="textXS" color="gray20_1">
                    {item.text}
                  </Typography>
                </Box>
              )
          })}
        </Box>
      </Box>
    </Box>
  );
};
