import { Typography } from "../../Typography/Typography.jsx";
import { Box } from "../../Box/Box.jsx";
import { Spacer } from "../../Spacer/Spacer.jsx";

import "./Block1.css";

export const Block1 = ({ data }) => {
    const block1 = data;
    return (
        <Box justifyContent="center" className="hu_block1_1">
            <Box justifyContent="center" flexDirection="column" className="hu_block1_2">
                <Typography component="h1" variant="h1_2" color="main_1" className="hu_block1_tg1">
                    {block1.title}
                </Typography>
                <Spacer size={15} tabSize={0} mobSize={0} />
                <Typography component="p" variant="h3" color="main_1" className="hu_block1_tg2">
                    {block1.subtitle}
                </Typography>
                <Spacer size={20} tabSize={0} mobSize={0} />
                <a
                    href={block1.button.url}
                    target="_blank"
                    rel="noreferrer"
                    className="hu_block1_button"
                >
                    <Typography component="span" variant="textXS" color="black-1">
                        {block1.button.label}
                    </Typography>
                </a>
            </Box>
        </Box>
    );
};