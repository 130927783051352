import { Typography } from "../../Typography/Typography.jsx";
import { Box } from "../../Box/Box.jsx";
import { Spacer } from "../../Spacer/Spacer.jsx";

import "./Block6.css";

export const Block6 = ({ data }) => {
    const block6 = data;
    return (
        <Box justifyContent="center" className="">
            <Box flexDirection="column" className="hu_block6_2">
                <Typography component="h2" variant="h2_2" color="main_1" className="hu_block6_tg1">
                    {block6.title}
                </Typography>
                <Spacer size={55} tabSize={55} mobSize={20} />
                <Typography component="p" variant="textM" color="black_1" className="hu_block6_tg2">
                    {block6.quote}
                </Typography>
                <Typography component="span" variant="textS" color="gray30_1" className="hu_block6_tg3">
                    {block6.label}
                </Typography>
            </Box>
        </Box>
    );
};